import QRCode, { QRCodeToDataURLOptions } from 'qrcode';
import { useBuildType } from 'src/hooks/_use_build_type';
import { useSelector } from 'react-redux';
import { getCrmData } from 'src/store/app/selectors';
import { isNaN, isFinite } from 'lodash';
import { useIsDevMode } from 'src/hooks/app';
import { isNumeric } from 'src/helpers/isNumeric';

export const useQRCode = () => {
  const { isCrossTable } = useBuildType();
  const { isDevMode } = useIsDevMode();
  const crmData = useSelector(getCrmData);
  const getQrCode = async (link: string, options?: QRCodeToDataURLOptions) => {
    let result = '';
    try {
      result = await QRCode.toDataURL(link, options);
    } catch (err) {
      result = '';
    }
    return result;
  };
  const getLink = () => {
    if (isCrossTable || crmData) {
      return '';
    }
    const string = new URL(document.URL).pathname.split('/');
    const complexShortName: string = string[string.length - 1];
    if ((complexShortName.length && !isNumeric(complexShortName)) || isDevMode) {
      return `https://flatshow.property/${window.location.pathname}${window.location.hash}`;
    }
    return '';
  };

  return {
    getQrCode,
    getLink,
  };
};
